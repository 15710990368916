import { Alert, Snackbar } from '@mui/material';
import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { AlertType } from '../types/Game';

export default function AlertSnackbar({
  alert: { alertData, setAlertData }
}: Readonly<{
  alert: {
    alertData: AlertType;
    setAlertData: Dispatch<SetStateAction<AlertType>>;
  };
}>) {
  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertData({ show: false, severity: alertData.severity, message: alertData.message });
  };

  return (
    <Snackbar
      open={alertData.show}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertData.severity} sx={{ width: '100%' }}>
        {alertData.message}
      </Alert>
    </Snackbar>
  );
}
