import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import './Cards.css';

function LobbyCards() {
  const [cardAmount, setCardAmount] = useState(5);

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const cardBack = require('../../images/cards/back.png');

  const useCard = () => {
    setCardAmount(cardAmount - 1);
  };

  const drawCard = () => {
    const cards = document.getElementById('cards');

    if ((cards?.childNodes?.length ?? 0) >= 6) return;

    setCardAmount(cardAmount + 1);
  };

  return (
    <Grid item className="Card-section">
      <Box className="Game-panel">
        <Typography variant="h5">Your Cards</Typography>
        <Box className="Player-cards">
          <Grid id="cards" container spacing={2}>
            {[...Array(cardAmount)].map((_, index) => (
              <Grid item key={index} xs={4}>
                <img
                  src={cardBack}
                  className="Card"
                  alt="card"
                  onMouseEnter={(element) => {
                    element.currentTarget.style.animation = 'shake 1s linear 0s infinite';
                  }}
                  onMouseLeave={(element) => {
                    element.currentTarget.style.animation = '';
                  }}
                  onClick={useCard}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Button variant="contained" color="primary" onClick={drawCard}>
          Draw Card
        </Button>
      </Box>
    </Grid>
  );
}

export default LobbyCards;
