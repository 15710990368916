import { LoadingButton } from '@mui/lab';
import { Alert, Box, Snackbar, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { SERVER } from '../modules/Constants';

function Lobby() {
  const [cookies, setCookie] = useCookies(['name', 'gameId', 'sessionId']);

  const [invalidGameCode, setInvalidGameCode] = useState(false);
  const [alertData, setAlertData] = useState({ show: false, message: '' });
  const [createLoading, setCreateLoading] = useState(false);
  const [joinLoading, setJoinLoading] = useState(false);

  const sanitizeGameCode = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInvalidGameCode(false);
    setAlertData({ show: false, message: '' });

    if (e.target.value.length > 4) {
      e.target.value = e.target.value.substring(0, 4);
    }

    e.target.value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
  };

  const setGameCode = (code: string) => {
    setCookie('gameId', code, {
      path: '/',
      maxAge: 60 * 60 * 24,
      sameSite: 'strict'
    });
  };

  const handleJoinGame = () => {
    const code = document.querySelector('input')?.value;

    if (!code || code.length !== 4) {
      setInvalidGameCode(true);
      setAlertData({ show: true, message: 'Invalid game code' });
      return;
    }

    setJoinLoading(true);

    axios
      .get(`${SERVER}/game/${code}`)
      .then((res) => {
        if (res.status === 200) {
          setGameCode(code);
        } else {
          setInvalidGameCode(true);
          setAlertData({ show: true, message: 'Game not found' });
        }
      })
      .catch((err) => {
        setInvalidGameCode(true);

        if (err.response?.status === 404) {
          setAlertData({ show: true, message: 'Game not found' });
        } else {
          setAlertData({ show: true, message: 'Server error' });
        }
      })
      .finally(() => setJoinLoading(false));
  };

  const handleCreateGame = () => {
    setCreateLoading(true);

    axios
      .post(`${SERVER}/game`, { name: cookies.name, sessionId: cookies.sessionId })
      .then((res) => {
        if (res.status === 201) {
          setGameCode(res.data.gameId);
        }
      })
      .catch(() => setAlertData({ show: true, message: 'Server error' }))
      .finally(() => setCreateLoading(false));
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertData({ show: false, message: alertData.message });
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleJoinGame();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Box className="Main">
      <Box className="Home-content">
        <img src={require('../images/logo.png')} className="App-logo" alt="logo" />
        <LoadingButton
          className="Home-button"
          variant="contained"
          loading={createLoading}
          onClick={handleCreateGame}>
          Create Game
        </LoadingButton>
        <LoadingButton
          sx={{ marginTop: '20px' }}
          className="Home-button"
          variant="contained"
          loading={joinLoading}
          onClick={handleJoinGame}>
          Join Game
        </LoadingButton>
        <TextField
          color="secondary"
          sx={{ marginTop: '20px' }}
          className="Home-input"
          label="Game Code"
          variant="filled"
          error={invalidGameCode}
          onChange={sanitizeGameCode}
        />
        <Snackbar
          open={alertData.show}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {alertData.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

export default Lobby;
