import { Box } from '@mui/material';
import Rules from './Rules/Rules';
import Settings from './Settings/Settings';
import './FABs.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function FABs({ howler }: Readonly<{ howler: any }>) {
  return (
    <Box className="FAB-container">
      <Rules />
      <Settings howler={howler} />
    </Box>
  );
}
