import { Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import useSound from 'use-sound';
import AlertSnackbar from '../components/AlertSnackbar';
import Board from '../components/Board/Board';
import Cards from '../components/Cards/Cards';
import LobbyCards from '../components/Cards/LobbyCards';
import FABs from '../components/FABs/FABs';
import CrownIcon from '../components/icons/CrownIcon';
import LastPlayedIcon from '../components/icons/LastPlayedIcon';
import LobbyPlayers from '../components/Players/LobbyPlayers';
import Players from '../components/Players/Players';
import { getChipRadius, getComputedPositions, Position } from '../modules/Board';
import { Card, getJackType, JackType } from '../modules/Cards';
import { disconnectSocket, initializeSocket, socket } from '../modules/Socket';
import { AlertType, GameLog, GamePhase, GameType, isWinningTeam, Team } from '../types/Game';
import './Game.css';

function Game() {
  const [{ name, gameId, sessionId }, , removeCookie] = useCookies(['name', 'gameId', 'sessionId']);

  const [game, setGame] = useState<GameType>({
    gameId,
    players: [{ name, sessionId, team: Team.BLUE }],
    started: false,
    phase: GamePhase.PLAYING,
    currentTurn: 0,
    board: [],
    winningTeam: ''
  });
  const [hand, setHand] = useState<Card[]>([]);
  const [selectedCard, setSelectedCard] = useState<Card | undefined>(undefined);
  const [gameLog, setGameLog] = useState<GameLog[]>([]);

  const [alertData, setAlertData] = useState<AlertType>({
    show: false,
    severity: 'error',
    message: ''
  });

  const lastChip = useRef<{ position: Position; team: string } | undefined>(undefined);

  // This is a workaround to prevent the user from drawing multiple cards
  const waitingForCard = useRef(false);

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const sound = require('../sounds/players_turn.mp3');
  const [playSound, { sound: howler }] = useSound(sound, { volume: 0.5 });

  const updateGameState = (newState: Partial<GameType>) => {
    setGame((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  const playCard = (card: Card, position: Position) => {
    const playerIndex = game.players.findIndex((player) => player.sessionId === sessionId);

    if (game.currentTurn !== playerIndex || game.phase !== GamePhase.PLAYING) {
      setAlertData({ show: true, severity: 'error', message: 'It is not your turn' });
      return;
    }

    const removingJack = getJackType(card) === JackType.REMOVE;

    if (removingJack) {
      if (!game.board[position.y][position.x]) {
        setAlertData({ show: true, severity: 'error', message: 'There is no chip on that card' });
        return;
      }
      if (game.board[position.y][position.x] === game.players[playerIndex].team) {
        setAlertData({ show: true, severity: 'error', message: 'You cannot remove your own chip' });
        return;
      }
      if (isWinningTeam(game.board[position.y][position.x])) {
        setAlertData({
          show: true,
          severity: 'error',
          message: 'You cannot remove a winning chip'
        });
        return;
      }
    }

    const placingJack = getJackType(card) === JackType.PLACE;

    if (placingJack && game.board[position.y][position.x]) {
      setAlertData({
        show: true,
        severity: 'error',
        message: 'There is already a chip on that card'
      });
      return;
    }

    socket?.emit('use-card', { sessionId, card, location: position });

    setSelectedCard(undefined);
    clearAllAnimations();
    clearOverlay();
  };

  const clearLastChip = (replaceChip: boolean) => {
    if (!lastChip.current) return;

    const canvas = document.getElementById('board') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const { start, end, center } = getComputedPositions(lastChip.current.position, {
      width: canvas.width,
      height: canvas.height
    });

    ctx.clearRect(start.x, start.y, end.x - start.x, end.y - start.y);

    if (replaceChip && lastChip.current.team) {
      ctx.beginPath();
      ctx.arc(center.x, center.y, getChipRadius(start.x, end.x), 0, 2 * Math.PI, false);
      ctx.fillStyle = lastChip.current.team;
      ctx.fill();
    }
  };

  const drawLastChip = () => {
    if (!lastChip.current) return;

    const canvas = document.getElementById('board') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const { start, end, center } = getComputedPositions(lastChip.current.position, {
      width: canvas.width,
      height: canvas.height
    });

    if (lastChip.current.team) {
      ctx.fillStyle = 'yellow';
      ctx.fill(LastPlayedIcon(center));
      return;
    }

    const radius = getChipRadius(start.x, end.x);

    ctx.beginPath();
    ctx.arc(center.x, center.y, radius * 0.75, 0, 2 * Math.PI, false);
    ctx.fillStyle = 'orange';
    ctx.fill();

    const image = document.createElement('img');
    image.src = 'DeleteIcon.png';
    image.onload = () => ctx.drawImage(image, center.x - 10, center.y - 10, 20, 20);
  };

  const drawCardChip = (position: Position, team: Team | undefined) => {
    if (!position) return;

    const canvas = document.getElementById('board') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    if (lastChip.current) {
      clearLastChip(true);
      lastChip.current = undefined;
    }

    const { start, end, center } = getComputedPositions(position, {
      width: canvas.width,
      height: canvas.height
    });

    const radius = getChipRadius(start.x, end.x);

    if (!team) {
      ctx.clearRect(start.x, start.y, end.x - start.x, end.y - start.y);
      lastChip.current = { position, team: '' };
      drawLastChip();
      return;
    }

    const isWin = isWinningTeam(team);
    const color = isWin ? team.slice(0, -4) : team;

    ctx.beginPath();
    ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = color;
    ctx.fill();

    ctx.fillStyle = 'yellow';
    if (isWin) {
      ctx.fill(CrownIcon(center));
    } else {
      lastChip.current = { position, team: color };
      drawLastChip();
    }
  };

  const drawCardChips = (positions: (Position & { team: Team })[]) => {
    const canvas = document.getElementById('board') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    positions.forEach(({ x, y, team }) => {
      const { start, end, center } = getComputedPositions(
        { x, y },
        {
          width: canvas.width,
          height: canvas.height
        }
      );

      const radius = getChipRadius(start.x, end.x);

      const isWin = isWinningTeam(team);
      const color = isWin ? team.slice(0, -4) : team;

      ctx.beginPath();
      ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = color;
      ctx.fill();

      ctx.fillStyle = 'yellow';
      if (isWin) {
        ctx.fill(CrownIcon(center));
      }
    });
  };

  const clearAllAnimations = () => {
    document.querySelectorAll('.Card').forEach((card) => {
      (card as HTMLImageElement).style.animation = '';
    });
  };

  const clearOverlay = () => {
    const canvas = document.getElementById('overlay') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const resetBoard = () => {
    const board = document.getElementById('board') as HTMLCanvasElement;
    const ctx = board.getContext('2d') as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, board.width, board.height);

    const overlay = document.getElementById('overlay') as HTMLCanvasElement;
    const overlayCtx = overlay.getContext('2d') as CanvasRenderingContext2D;
    overlayCtx.clearRect(0, 0, overlay.width, overlay.height);
  };

  useEffect(() => {
    if (game.players.length === 0 || !game.started) return;

    if (
      game.players[game.currentTurn]?.sessionId === sessionId &&
      game.phase === GamePhase.PLAYING
    ) {
      playSound();
    }
  }, [game]);

  useEffect(() => {
    const socket = initializeSocket(name, gameId, sessionId);

    socket.on('connect', async () => {
      const validGame: boolean = await socket.emitWithAck('valid-game');

      if (!validGame) {
        removeCookie('gameId');
        return;
      }

      const game: GameType = await socket.emitWithAck('get-state');
      updateGameState(game);

      if (game.started) {
        const hand: Card[] = await socket.emitWithAck('get-hand');
        setHand(hand);
      }

      const retryLoop = setInterval(() => {
        if (document.getElementById('board')?.style.display === 'block') {
          clearInterval(retryLoop);

          const chips = Array<number[]>(10)
            .fill(Array.from(Array(10).keys()))
            .flatMap((array, y) => array.map((_, x) => ({ x, y, team: game.board[y][x] })))
            .filter(({ team }) => team);

          drawCardChips(chips);
        }
      }, 200);
    });

    socket.on('message', (...args) => {
      if (!socket.connected) return;

      const [event, data] = args;

      switch (event) {
        case 'game-state':
          updateGameState(data as GameType);
          waitingForCard.current = false;
          break;
        case 'set-hand':
          setHand(data as Card[]);
          break;
        case 'play-card':
          drawCardChip(data.location, data.team);
          setGameLog((prevState) => [
            ...prevState,
            {
              player: { name: data.player.name, team: data.player.team },
              card: data.card
            }
          ]);
          break;
        case 'draw-winning-chips':
          data.pieces.forEach((position: Position) =>
            drawCardChip(position, `${data.team}-win` as Team)
          );
          break;
        case 'game-over': {
          const teamName = data.substring(0, 1).toUpperCase() + data.substring(1).toLowerCase();

          updateGameState({ currentTurn: -1, phase: GamePhase.GAME_OVER, winningTeam: teamName });
          setAlertData({ show: true, severity: 'success', message: `Game over! ${teamName} won` });
          break;
        }
        case 'reset-board':
          resetBoard();
          setGameLog([]);
          break;
        default:
          console.log('Unknown event:', event);
          break;
      }
    });

    return () => {
      socket.off('message');
      socket.off('player-join');
      socket.off('player-leave');
      disconnectSocket();
    };
  }, []);

  return (
    <Grid container flexWrap={'nowrap'} className="Main">
      {game.started ? (
        <Players game={game} gameLog={gameLog} />
      ) : (
        <LobbyPlayers game={game} setAlertData={setAlertData} />
      )}
      <Board game={game} card={{ selectedCard }} functions={{ playCard }} />
      {game.started ? (
        <Cards
          game={game}
          hand={hand}
          lastChip={lastChip}
          card={{ selectedCard, setSelectedCard, waitingForCard }}
          functions={{
            playCard,
            clearAllAnimations,
            clearOverlay,
            setAlertData,
            clearLastChip,
            drawLastChip
          }}
        />
      ) : (
        <LobbyCards />
      )}
      <FABs howler={howler} />
      <AlertSnackbar alert={{ alertData, setAlertData }} />
    </Grid>
  );
}

export default Game;
