import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import { getFullCardName } from '../../modules/Cards';
import { socket } from '../../modules/Socket';
import { GameLog, GamePhase, GameType } from '../../types/Game';
import MiniDivider from '../MiniDivider';
import './Players.css';

function Players({ game, gameLog }: Readonly<{ game: GameType; gameLog: GameLog[] }>) {
  const [, , removeCookie] = useCookies(['gameId']);

  return (
    <Grid item className="Player-section">
      <Box className="Game-panel">
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h5">Game ID: </Typography>
          <Typography variant="h5" color="primary" sx={{ marginLeft: '5px' }}>
            {game.gameId}
          </Typography>
        </Box>
        <MiniDivider />
        <Typography variant="h5">Players</Typography>
        <Box className="Players">
          {game.players.map((player, index) => (
            <Box key={`${player.name}-${index}`} className="Player">
              {game.currentTurn === index && (
                <ArrowForwardIcon sx={{ margin: 'auto 5px' }} color="error"></ArrowForwardIcon>
              )}
              <Typography variant="h6">{player.name}</Typography>
              <Box className="Team-circle" sx={{ backgroundColor: player.team }}></Box>
            </Box>
          ))}
        </Box>
        <MiniDivider />
        <Typography variant="h5">Game Log</Typography>
        <Box className="Game-log">
          {gameLog
            .slice(-5)
            .reverse()
            .map((log, index) => {
              let opacity = 1;

              switch (index) {
                case 1:
                  opacity = 0.9;
                  break;
                case 2:
                  opacity = 0.8;
                  break;
                case 3:
                  opacity = 0.5;
                  break;
                case 4:
                  opacity = 0.2;
                  break;
              }

              const player = <Typography color={log.player.team}>{log.player.name}</Typography>;

              const cardText = getFullCardName(log.card);
              const cardColor = cardText.includes('Jack') ? 'primary' : '#fff';
              const cardElement = <Typography color={cardColor}>{cardText}</Typography>;

              return (
                <Box key={`${log.player.name}-${index}`} sx={{ opacity }} display={'ruby'}>
                  {player}
                  &nbsp;played&nbsp;
                  {cardElement}
                </Box>
              );
            })}
        </Box>
        {game.phase === GamePhase.GAME_OVER && (
          <>
            <MiniDivider />
            <Box className="Game-over">
              <Typography variant="h5">Game Over!</Typography>
              <Box sx={{ display: 'ruby' }}>
                <Typography variant="h5" color={game.winningTeam}>
                  {game.winningTeam}&nbsp;
                </Typography>
                <Typography variant="h5">team won</Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ margin: '10px' }}
                onClick={() => socket?.emit('reset-game')}>
                Rematch
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ margin: '10px' }}
                onClick={() => {
                  removeCookie('gameId');
                }}>
                Back to Lobby
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Grid>
  );
}

export default Players;
