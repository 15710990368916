import QuestionMark from '@mui/icons-material/QuestionMark';
import { Backdrop, Box, Card, Fab, Typography } from '@mui/material';
import { useState } from 'react';
import './Rules.css';

export default function Rules() {
  const [open, setOpen] = useState(false);

  const textSpacing = { margin: '10px 20px' };

  const bullet = (
    <Box component="span" mx={'2px'}>
      &nbsp;&nbsp;•
    </Box>
  );

  const handleClose: React.MouseEventHandler<HTMLElement> = (event) => {
    // don't do anything if the click is inside the modal
    if (event.target !== event.currentTarget) return;
    setOpen(false);
  };

  return (
    <>
      <Fab color="primary" aria-label="rules" onClick={() => setOpen(true)}>
        <QuestionMark />
      </Fab>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}>
        <Card
          className="Rules-content"
          variant="outlined"
          sx={{ color: 'primary.light', overflowY: 'auto' }}>
          <Typography variant="h4" align="center" marginTop={'20px'}>
            How to Play
          </Typography>
          <Typography variant="h6" sx={textSpacing}>
            Setup
          </Typography>
          <Typography sx={textSpacing}>
            The game is played with 2 or 3 teams. Before the game has started, you can change each
            player&apos;s team by clicking the colored circle next to their name. Each player is
            dealt some cards with the amount being dependent on how many players there are. The
            remaining cards are placed in a draw pile. <br />
            {bullet} 2 players: 7 cards each <br />
            {bullet} 3-4 players: 6 cards each <br />
            {bullet} 6 players: 5 cards each <br />
            {bullet} 8-9 players: 4 cards each <br />
            {bullet} 10-12 players: 3 cards each <br />
            {bullet} 14+ players: 2 cards each <br />
          </Typography>
          <Typography variant="h6" sx={textSpacing}>
            Objective
          </Typography>
          <Typography sx={textSpacing}>
            The objective of the game is to be the first team to make 1 or 2 Sequences. A Sequence
            is a series of 5 chips of the same color in a row, either horizontally, vertically, or
            diagonally. If there are 3 teams, then the first team to make 1 Sequence wins. If there
            are 2 teams, then the first team to make 2 Sequences wins. The Jokers in the corners are
            free spaces and can be used by any team.
          </Typography>
          <Typography variant="h6" sx={textSpacing}>
            Gameplay
          </Typography>
          <Typography sx={textSpacing}>
            The game is played in turns. On your turn, you start by choosing a card to play. You may
            hover over your cards to see where they are able to be played. Each card has 2 available
            spots on the board. After playing a card, you have 5 seconds to draw a card. If you
            forget to draw, you play with 1 card less for the remainder of the game. <br />
            <br />
            The Jack card is special and has 2 functions: <br />
            {bullet} 2-Eyed Jack: Place a chip anywhere on the board. <br />
            {bullet} 1-Eyed Jack: Remove a chip from anywhere on the board. <br />
            <br />
          </Typography>
          <Box className="Jack-titles">
            <Typography variant="h6" align="center" flex={1} sx={textSpacing}>
              One-Eyed Jack
            </Typography>
            <Typography variant="h6" align="center" flex={1} sx={textSpacing}>
              Two-Eyed Jack
            </Typography>
          </Box>
          <Box className="Jack-cards">
            <img
              className="Jack-card"
              src={require('../../../images/cards/JS.png')}
              alt="Jack of Spades"
            />
            <img
              className="Jack-card"
              src={require('../../../images/cards/JH.png')}
              alt="Jack of Hearts"
            />
            <img
              className="Jack-card"
              src={require('../../../images/cards/JC.png')}
              alt="Jack of Clubs"
            />
            <img
              className="Jack-card"
              src={require('../../../images/cards/JD.png')}
              alt="Jack of Diamonds"
            />
          </Box>
        </Card>
      </Backdrop>
    </>
  );
}
