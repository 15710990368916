import { VolumeMute, VolumeUp } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Backdrop, Card, Fab, Slider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import './Settings.css';

export default function Settings({
  howler
}: Readonly<{ howler: { volume: (value?: number) => number } }>) {
  const [open, setOpen] = useState(false);
  const [volume, setVolume] = useState(50);

  const handleClose: React.MouseEventHandler<HTMLElement> = (event) => {
    // don't do anything if the click is inside the modal
    if (event.target !== event.currentTarget) return;
    setOpen(false);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    howler?.volume((newValue as number) / 100);
    setVolume(newValue as number);
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="settings"
        sx={{ marginLeft: '10px' }}
        onClick={() => setOpen(true)}>
        <SettingsIcon />
      </Fab>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}>
        <Card className="Settings-content" variant="outlined" sx={{ color: 'primary.light' }}>
          <Typography variant="h6" marginRight={'10px'}>
            Sound Volume
          </Typography>
          <Stack className="Volume-slider" spacing={2} direction="row" alignItems="center">
            <VolumeMute />
            <Slider aria-label="Volume" value={volume} onChange={handleChange} />
            <VolumeUp />
          </Stack>
        </Card>
      </Backdrop>
    </>
  );
}
