import { AlertColor } from '@mui/material';
import { Card } from '../modules/Cards';

export type GameType = {
  gameId: string;
  players: Player[];
  started: boolean;
  phase: GamePhase;
  currentTurn: number;
  board: Team[][];
  winningTeam: string;
};

export type Player = {
  name: string;
  sessionId: string;
  team: Team;
};

export enum GamePhase {
  PLAYING,
  DRAWING,
  GAME_OVER
}

export enum Team {
  BLUE = 'blue',
  GREEN = 'green',
  RED = 'red',
  BLUE_WIN = 'blue-win',
  GREEN_WIN = 'green-win',
  RED_WIN = 'red-win'
}

export type GameLog = {
  player: {
    name: string;
    team: Team;
  };
  card: Card;
};

export type AlertType = {
  show: boolean;
  severity: AlertColor;
  message: string;
};

export const isWinningTeam = (team: Team | undefined) => {
  return team === Team.BLUE_WIN || team === Team.GREEN_WIN || team === Team.RED_WIN;
};
