import { Position } from '../../modules/Board';

export const LastPlayedIcon = (center: Position) => {
  const path = new Path2D();

  path.addPath(
    new Path2D(
      'M11 5v5.59H7.5l4.5 4.5 4.5-4.5H13V5h-2zm-5 9c0 3.31 2.69 6 6 6s6-2.69 6-6h-2c0 2.21-1.79 4-4 4s-4-1.79-4-4H6z'
    ),
    { e: center.x - 12, f: center.y - 12 }
  );

  return path;
};

export default LastPlayedIcon;
