import { Card, CardSuit, CardValue } from './Cards';

const ROWS = 10;
const COLS = 10;

const SIZE_MULTIPLIER = 2;

const CARD_WIDTH = 50 * SIZE_MULTIPLIER;
const CARD_HEIGHT = 73 * SIZE_MULTIPLIER;

const FILLER_OUTER = 20 * SIZE_MULTIPLIER;
const FILLER_INNER = 5 * SIZE_MULTIPLIER;

const WIDTH = COLS * CARD_WIDTH + FILLER_OUTER * 2 + FILLER_INNER * (COLS - 1);
const HEIGHT = ROWS * CARD_HEIGHT + FILLER_OUTER * 2 + FILLER_INNER * (ROWS - 1);

export const cardPositions = new Map<Card, Position[]>();
const computedPositions = new Map<Position, ComputedPosition>();

export const getCardPositions = (card: Card): Position[] => {
  for (const [c, positions] of cardPositions.entries()) {
    if (c.suit === card.suit && c.value === card.value) {
      return positions;
    }
  }

  return [];
};

export const getComputedPositions = (
  { x, y }: Position,
  { width, height }: Size
): ComputedPosition => {
  for (const [position, positions] of computedPositions.entries()) {
    if (position.x === x && position.y === y) {
      return positions;
    }
  }

  const widthRatio = width / WIDTH;
  const heightRatio = height / HEIGHT;

  const start = {
    x: FILLER_OUTER + x * (CARD_WIDTH + FILLER_INNER),
    y: FILLER_OUTER + y * (CARD_HEIGHT + FILLER_INNER)
  };

  start.x *= widthRatio;
  start.y *= heightRatio;

  const end = {
    x: start.x + CARD_WIDTH * widthRatio,
    y: start.y + CARD_HEIGHT * heightRatio
  };

  const center = {
    x: (start.x + end.x) / 2,
    y: (start.y + end.y) / 2
  };

  computedPositions.set({ x, y }, { start, end, center });

  return {
    start,
    end,
    center
  };
};

export const getCardFromPosition = (
  { x, y }: Position,
  { width, height }: Size
): { card: Card; position: Position } | { card: undefined; position: undefined } => {
  for (const [card, positions] of cardPositions.entries()) {
    for (const p of positions) {
      const { start, end } = getComputedPositions(p, { width, height });

      if (x >= start.x && x <= end.x && y >= start.y && y <= end.y) {
        return { card, position: p };
      }
    }
  }

  return { card: undefined, position: undefined };
};

export const getChipRadius = (start: number, end: number): number => {
  return (end - start) / 2 - 2;
};

(() => {
  cardPositions.set({ suit: CardSuit.Joker, value: CardValue.Joker }, [
    { x: 0, y: 0 },
    { x: 9, y: 0 },
    { x: 0, y: 9 },
    { x: 9, y: 9 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Two }, [
    { x: 1, y: 0 },
    { x: 6, y: 8 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Three }, [
    { x: 2, y: 0 },
    { x: 5, y: 8 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Four }, [
    { x: 3, y: 0 },
    { x: 4, y: 8 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Five }, [
    { x: 4, y: 0 },
    { x: 3, y: 8 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Six }, [
    { x: 5, y: 0 },
    { x: 2, y: 8 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Seven }, [
    { x: 6, y: 0 },
    { x: 1, y: 8 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Eight }, [
    { x: 7, y: 0 },
    { x: 1, y: 7 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Nine }, [
    { x: 8, y: 0 },
    { x: 1, y: 6 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Ten }, [
    { x: 9, y: 1 },
    { x: 1, y: 5 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Queen }, [
    { x: 9, y: 2 },
    { x: 1, y: 4 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.King }, [
    { x: 9, y: 3 },
    { x: 1, y: 3 }
  ]);
  cardPositions.set({ suit: CardSuit.Spades, value: CardValue.Ace }, [
    { x: 9, y: 4 },
    { x: 1, y: 2 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Two }, [
    { x: 9, y: 5 },
    { x: 2, y: 2 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Three }, [
    { x: 9, y: 6 },
    { x: 3, y: 2 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Four }, [
    { x: 9, y: 7 },
    { x: 4, y: 2 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Five }, [
    { x: 9, y: 8 },
    { x: 5, y: 2 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Six }, [
    { x: 8, y: 9 },
    { x: 6, y: 2 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Seven }, [
    { x: 7, y: 9 },
    { x: 7, y: 2 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Eight }, [
    { x: 6, y: 9 },
    { x: 7, y: 3 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Nine }, [
    { x: 5, y: 9 },
    { x: 7, y: 4 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Ten }, [
    { x: 4, y: 9 },
    { x: 7, y: 5 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Queen }, [
    { x: 3, y: 9 },
    { x: 7, y: 6 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.King }, [
    { x: 2, y: 9 },
    { x: 7, y: 7 }
  ]);
  cardPositions.set({ suit: CardSuit.Diamonds, value: CardValue.Ace }, [
    { x: 1, y: 9 },
    { x: 6, y: 7 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Two }, [
    { x: 4, y: 1 },
    { x: 6, y: 3 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Three }, [
    { x: 3, y: 1 },
    { x: 5, y: 3 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Four }, [
    { x: 2, y: 1 },
    { x: 4, y: 3 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Five }, [
    { x: 1, y: 1 },
    { x: 3, y: 3 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Six }, [
    { x: 0, y: 1 },
    { x: 2, y: 3 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Seven }, [
    { x: 0, y: 2 },
    { x: 2, y: 4 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Eight }, [
    { x: 0, y: 3 },
    { x: 2, y: 5 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Nine }, [
    { x: 0, y: 4 },
    { x: 2, y: 6 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Ten }, [
    { x: 0, y: 5 },
    { x: 2, y: 7 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Queen }, [
    { x: 0, y: 6 },
    { x: 3, y: 7 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.King }, [
    { x: 0, y: 7 },
    { x: 4, y: 7 }
  ]);
  cardPositions.set({ suit: CardSuit.Clubs, value: CardValue.Ace }, [
    { x: 0, y: 8 },
    { x: 5, y: 7 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Two }, [
    { x: 7, y: 8 },
    { x: 4, y: 5 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Three }, [
    { x: 8, y: 8 },
    { x: 5, y: 5 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Four }, [
    { x: 8, y: 7 },
    { x: 5, y: 4 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Five }, [
    { x: 8, y: 6 },
    { x: 4, y: 4 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Six }, [
    { x: 8, y: 5 },
    { x: 3, y: 4 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Seven }, [
    { x: 8, y: 4 },
    { x: 3, y: 5 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Eight }, [
    { x: 8, y: 3 },
    { x: 3, y: 6 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Nine }, [
    { x: 8, y: 2 },
    { x: 4, y: 6 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Ten }, [
    { x: 8, y: 1 },
    { x: 5, y: 6 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Queen }, [
    { x: 7, y: 1 },
    { x: 6, y: 6 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.King }, [
    { x: 6, y: 1 },
    { x: 6, y: 5 }
  ]);
  cardPositions.set({ suit: CardSuit.Hearts, value: CardValue.Ace }, [
    { x: 5, y: 1 },
    { x: 6, y: 4 }
  ]);
})();

export type ComputedPosition = { start: Position; end: Position; center: Position };
export type Position = { x: number; y: number };
type Size = { width: number; height: number };
