import { Box, Button, Grid, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import {
  getCardPositions,
  getChipRadius,
  getComputedPositions,
  Position
} from '../../modules/Board';
import { Card, CardValue, getCardName } from '../../modules/Cards';
import { socket } from '../../modules/Socket';
import { AlertType, GamePhase, GameType } from '../../types/Game';
import {
  Dispatch,
  MouseEvent,
  MouseEventHandler,
  MutableRefObject,
  SetStateAction,
  useRef
} from 'react';
import './Cards.css';

function Cards({
  game,
  hand,
  lastChip,
  card: { selectedCard, setSelectedCard, waitingForCard },
  functions: {
    playCard,
    clearAllAnimations,
    clearOverlay,
    setAlertData,
    clearLastChip,
    drawLastChip
  }
}: Readonly<{
  game: GameType;
  hand: Card[];
  lastChip: MutableRefObject<
    | {
        position: Position;
        team: string;
      }
    | undefined
  >;
  card: {
    selectedCard: Card | undefined;
    setSelectedCard: (card: Card | undefined) => void;
    waitingForCard: MutableRefObject<boolean>;
  };
  functions: {
    playCard: (card: Card, position: Position) => void;
    clearAllAnimations: () => void;
    clearOverlay: () => void;
    setAlertData: Dispatch<SetStateAction<AlertType>>;
    clearLastChip: (replaceChip: boolean) => void;
    drawLastChip: () => void;
  };
}>) {
  const [{ sessionId }] = useCookies(['sessionId']);

  const removedLastChip = useRef(false);

  const hoverCard = (element: MouseEvent<HTMLImageElement, globalThis.MouseEvent>, card: Card) => {
    if (selectedCard) {
      return;
    }

    element.currentTarget.style.animation = 'shake 1s linear 0s infinite';

    if (card.value === CardValue.Jack) {
      return;
    }

    const canvas = document.getElementById('overlay') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const positions = getCardPositions(card);
    for (const position of positions) {
      const { start, end } = getComputedPositions(position, {
        width: canvas.width,
        height: canvas.height
      });

      ctx.fillStyle = 'rgba(255, 255, 0, 0.5)';
      ctx.fillRect(start.x, start.y, end.x - start.x, end.y - start.y);
    }
  };

  const unhoverCard: MouseEventHandler<HTMLImageElement> = (element) => {
    if (selectedCard) {
      return;
    }

    element.currentTarget.style.animation = '';
    clearOverlay();
  };

  const selectCard = (element: MouseEvent<HTMLImageElement, globalThis.MouseEvent>, card: Card) => {
    const playerIndex = game.players.findIndex((player) => player.sessionId === sessionId);

    if (game.currentTurn !== playerIndex) {
      setAlertData({ show: true, severity: 'error', message: 'It is not your turn' });
      return;
    }

    if (game.phase !== GamePhase.PLAYING) {
      setAlertData({
        show: true,
        severity: 'error',
        message: 'You have already played a card'
      });
      return;
    }

    if (selectedCard) {
      clearAllAnimations();

      if (removedLastChip.current) {
        drawLastChip();
        removedLastChip.current = false;
      }

      if (selectedCard === card) {
        setSelectedCard(undefined);
        clearOverlay();
        return;
      }
    }

    element.currentTarget.style.animation = 'shake 1s linear 0s infinite';
    clearOverlay();

    if (card.value === CardValue.Jack) {
      setSelectedCard(card);

      if (removedLastChip.current) {
        drawLastChip();
        removedLastChip.current = false;
      }
      return;
    }

    const positions = getCardPositions(card).filter(
      (position) => !game.board[position.y][position.x]
    );

    if (positions.length === 0) {
      socket?.emit('dead-card', { sessionId, card });
      setSelectedCard(undefined);
      setAlertData({
        show: true,
        severity: 'info',
        message: 'There was no spot left for that card so you drew another'
      });
      return;
    }

    if (positions.length === 1) {
      playCard(card, positions[0]);
      setAlertData({
        show: true,
        severity: 'info',
        message: 'Your card was played automatically at the only available spot'
      });
      return;
    }

    if (
      lastChip.current !== undefined &&
      positions.some(
        (position) =>
          position.x === lastChip.current?.position.x && position.y === lastChip.current?.position.y
      )
    ) {
      clearLastChip(false);
      removedLastChip.current = true;
    }

    const canvas = document.getElementById('overlay') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    for (let i = 0; i < positions.length; i++) {
      const position = positions[i];
      const { start, end, center } = getComputedPositions(position, {
        width: canvas.width,
        height: canvas.height
      });

      ctx.beginPath();
      ctx.arc(center.x, center.y, getChipRadius(start.x, end.x), 0, 2 * Math.PI, false);
      ctx.fillStyle = 'yellow';
      ctx.fill();

      ctx.font = '20px Arial';
      ctx.fillStyle = 'black';
      ctx.fillText(`${i + 1}`, center.x - 7, center.y + 8);
    }

    setSelectedCard(card);
  };

  const drawCard = async () => {
    const playerIndex = game.players.findIndex((player) => player.sessionId === sessionId);

    if (game.currentTurn !== playerIndex) {
      setAlertData({ show: true, severity: 'error', message: 'It is not your turn' });
      return;
    }

    if (waitingForCard.current) {
      return;
    }

    if (game.phase !== GamePhase.DRAWING) {
      setAlertData({
        show: true,
        severity: 'error',
        message: 'You must play a card before drawing'
      });
      return;
    }

    waitingForCard.current = true;

    const card: Card = await socket?.emitWithAck('draw-card', { gameId: game.gameId, sessionId });

    if (card) {
      hand.push(card);
    }
  };

  return (
    <Grid item className="Card-section">
      <Box className="Game-panel">
        <Typography variant="h5">Your Cards</Typography>
        <Box className="Player-cards">
          <Grid id="cards" container spacing={2}>
            {hand.map((card, index) => (
              <Grid item key={index} xs={4}>
                <img
                  src={require(`../../images/cards/${getCardName(card)}.png`)}
                  className="Card"
                  alt="card"
                  onClick={(e) => selectCard(e, card)}
                  onMouseEnter={(e) => hoverCard(e, card)}
                  onMouseLeave={(e) => unhoverCard(e)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        {selectedCard && selectedCard?.value !== CardValue.Jack && (
          <Box className="Card-selection">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '20px' }}
              onClick={() => playCard(selectedCard, getCardPositions(selectedCard)[0])}>
              Position #1
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '20px' }}
              onClick={() => playCard(selectedCard, getCardPositions(selectedCard)[1])}>
              Position #2
            </Button>
          </Box>
        )}
        {game.phase !== GamePhase.GAME_OVER && (
          <Button variant="contained" color="primary" onClick={drawCard}>
            Draw Card
          </Button>
        )}
      </Box>
    </Grid>
  );
}

export default Cards;
