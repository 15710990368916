import { Grid, Skeleton } from '@mui/material';
import { MouseEventHandler, PointerEvent, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getCardFromPosition, getComputedPositions, Position } from '../../modules/Board';
import { Card, CardValue, getJackType, JackType } from '../../modules/Cards';
import { GamePhase, GameType } from '../../types/Game';
import './Board.css';

function Board({
  game,
  card: { selectedCard },
  functions: { playCard }
}: Readonly<{
  game: GameType;
  card: { selectedCard: Card | undefined };
  functions: {
    playCard: (card: Card, position: Position) => void;
  };
}>) {
  const [{ sessionId }] = useCookies(['sessionId']);

  const [imageLoaded, setImageLoaded] = useState(false);

  const resizeCanvas = () => {
    const image = document.getElementById('board-image') as HTMLImageElement;

    for (const id of ['overlay', 'board']) {
      const canvas = document.getElementById(id) as HTMLCanvasElement;
      canvas.height = image.height;
      canvas.width = image.width;
      canvas.style.margin = image.offsetTop + 'px 0';
    }
  };

  const hoverUsingJack = (event: PointerEvent<HTMLCanvasElement>) => {
    if (selectedCard?.value !== CardValue.Jack) {
      return;
    }

    const canvas = document.getElementById('overlay') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const { card, position } = getCardFromPosition(
      { x, y },
      { width: canvas.width, height: canvas.height }
    );

    if (!position || card?.value === CardValue.Joker) {
      return;
    }

    const jackType = getJackType(selectedCard);

    if (
      (jackType === JackType.REMOVE && !game.board[position.y][position.x]) ||
      (jackType === JackType.PLACE && game.board[position.y][position.x])
    ) {
      return;
    }

    const { start, end } = getComputedPositions(position, {
      width: canvas.width,
      height: canvas.height
    });

    ctx.fillStyle = 'rgba(255, 255, 0, 0.5)';
    ctx.fillRect(start.x, start.y, end.x - start.x, end.y - start.y);
  };

  const playJackAtPosition: MouseEventHandler<HTMLCanvasElement> = (event) => {
    const playerIndex = game.players.findIndex((player) => player.sessionId === sessionId);

    if (game.currentTurn !== playerIndex || game.phase !== GamePhase.PLAYING) {
      return;
    }

    if (!selectedCard || selectedCard.value !== CardValue.Jack) {
      return;
    }

    const canvas = document.getElementById('board') as HTMLCanvasElement;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const { card: clickedCard, position } = getCardFromPosition(
      { x, y },
      { width: canvas.width, height: canvas.height }
    );

    if (!clickedCard || clickedCard.value === CardValue.Joker) {
      return;
    }

    const overlay = document.getElementById('overlay') as HTMLCanvasElement;
    const overlayCtx = overlay.getContext('2d') as CanvasRenderingContext2D;
    overlayCtx.clearRect(0, 0, overlay.width, overlay.height);

    playCard(selectedCard, position);
  };

  return (
    <Grid item className="Board-section">
      <img
        id="board-image"
        src={require('../../images/board.png')}
        className="Board"
        style={{ display: imageLoaded ? 'block' : 'none' }}
        alt="logo"
        onLoad={() => {
          setImageLoaded(true);
          setTimeout(() => resizeCanvas(), 100);
        }}
      />
      <canvas
        id="overlay"
        className="Board"
        style={{
          display: imageLoaded ? 'block' : 'none',
          position: 'fixed'
        }}></canvas>
      <canvas
        id="board"
        className="Board"
        style={{
          display: imageLoaded ? 'block' : 'none',
          position: 'fixed',
          cursor: selectedCard?.value === CardValue.Jack ? 'pointer' : 'default'
        }}
        onPointerMove={hoverUsingJack}
        onClick={playJackAtPosition}></canvas>
      <Skeleton
        width={'650px'}
        className="Board"
        style={{ display: imageLoaded ? 'none' : 'block' }}></Skeleton>
    </Grid>
  );
}

export default Board;
