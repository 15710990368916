import { io, Socket } from 'socket.io-client';
import { SOCKET_SERVER } from './Constants';

export let socket: Socket | undefined = undefined;

export const initializeSocket = (name: string, gameId: string, sessionId: string) => {
  if (socket) {
    return socket;
  }

  socket = io(SOCKET_SERVER, {
    query: {
      name,
      gameId,
      sessionId
    }
  });

  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = undefined;
  }
};
