import { Position } from '../../modules/Board';

// from https://icon-sets.iconify.design/mdi/crown/
export const CrownIcon = (center: Position) => {
  const path = new Path2D();

  path.addPath(
    new Path2D('M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11zm14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14z'),
    { e: center.x - 12, f: center.y - 12 }
  );

  return path;
};

export default CrownIcon;
