import { Box, Button, Grid, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import { disconnectSocket, socket } from '../../modules/Socket';
import { AlertType, GameType, Player, Team } from '../../types/Game';
import MiniDivider from '../MiniDivider';
import './Players.css';

function LobbyPlayers({
  game,
  setAlertData
}: Readonly<{
  game: GameType;
  setAlertData: React.Dispatch<React.SetStateAction<AlertType>>;
}>) {
  const [, , removeCookie] = useCookies(['gameId']);

  const setNextColor = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, player: Player) => {
    const element = event.target as HTMLDivElement;
    // start with green because it starts at blue but the bgcolor is inherited from sx
    const colors = [Team.GREEN, Team.RED, Team.BLUE];
    const currentColor = element.style.backgroundColor;
    const nextColor = colors[(colors.indexOf(currentColor as Team) + 1) % colors.length];

    element.style.backgroundColor = nextColor;
    player.team = nextColor;

    socket?.emit('update-player', { player });
  };

  const startGame = async () => {
    const response = await socket?.emitWithAck('start-game');

    if (response && typeof response === 'string') {
      setAlertData({ show: true, severity: 'error', message: response });
    }
  };

  return (
    <Grid item className="Player-section">
      <Box className="Game-panel">
        <Box display={'flex'}>
          <Typography variant="h5">Game ID: </Typography>
          <Typography variant="h5" color="primary" marginLeft={'5px'}>
            {game.gameId}
          </Typography>
        </Box>
        <MiniDivider />
        <Typography variant="h5">Players</Typography>
        <Box className="Players">
          {game.players.map((player, index) => (
            <Box key={`${player.name}-${index}`} className="Player">
              <Typography variant="h6">{player.name}</Typography>
              <Box
                className="Team-circle"
                style={{ backgroundColor: player.team }}
                sx={{ cursor: 'pointer' }}
                onClick={(event) => setNextColor(event, player)}></Box>
            </Box>
          ))}
        </Box>
        <MiniDivider />
        <Button variant="contained" color="primary" sx={{ margin: '10px' }} onClick={startGame}>
          Start Game
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ margin: '10px' }}
          onClick={() => {
            removeCookie('gameId');
            disconnectSocket();
          }}>
          Back to Lobby
        </Button>
      </Box>
    </Grid>
  );
}

export default LobbyPlayers;
