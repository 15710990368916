import { useCookies } from 'react-cookie';
import './App.css';
import Login from './pages/Login';
import Lobby from './pages/Lobby';
import Game from './pages/Game';

function App() {
  const [cookies] = useCookies(['name', 'gameId']);

  if (cookies.gameId && cookies.name) {
    return <Game />;
  }

  if (cookies.name) {
    return <Lobby />;
  }

  return <Login />;
}

export default App;
