export enum CardSuit {
  Hearts = 'H',
  Diamonds = 'D',
  Clubs = 'C',
  Spades = 'S',
  Joker = 'X'
}

export enum CardValue {
  Ace = 'A',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  Eight = '8',
  Nine = '9',
  Ten = '10',
  Jack = 'J',
  Queen = 'Q',
  King = 'K',
  Joker = 'X'
}

export interface Card {
  suit: CardSuit;
  value: CardValue;
}

export enum JackType {
  PLACE,
  REMOVE
}

export const getCardName = (card: Card): string => {
  return `${card.value}${card.suit}`;
};

export const getFullCardName = (card: Card): string => {
  let suitName = '';

  switch (card.suit) {
    case CardSuit.Clubs:
      suitName = 'Clubs';
      break;
    case CardSuit.Diamonds:
      suitName = 'Diamonds';
      break;
    case CardSuit.Hearts:
      suitName = 'Hearts';
      break;
    case CardSuit.Spades:
      suitName = 'Spades';
      break;
    case CardSuit.Joker:
      suitName = 'Joker';
      break;
  }

  switch (card.value) {
    case CardValue.Ace:
      return `Ace of ${suitName}`;
    case CardValue.Jack:
      return `Jack of ${suitName}`;
    case CardValue.Queen:
      return `Queen of ${suitName}`;
    case CardValue.King:
      return `King of ${suitName}`;
    case CardValue.Joker:
      return 'Joker';
    default:
      return `${card.value} of ${suitName}`;
  }
};

export const getJackType = (card: Card): JackType | undefined => {
  if (card.value !== CardValue.Jack) return undefined;

  return card.suit === CardSuit.Clubs || card.suit === CardSuit.Diamonds
    ? JackType.PLACE
    : JackType.REMOVE;
};
