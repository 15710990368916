import { Alert, Box, Button, Snackbar, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';

function Login() {
  const [, setCookie] = useCookies(['name', 'sessionId']);

  const [fieldEmpty, setFieldEmpty] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleLogin = () => {
    const username = document.querySelector('input')?.value;

    if (!username) {
      setFieldEmpty(true);
      setShowAlert(true);
      return;
    }

    setCookie('name', username.substring(0, 20), {
      path: '/',
      maxAge: 60 * 60 * 24 * 7,
      sameSite: 'strict'
    });

    setCookie('sessionId', uuidv4(), {
      path: '/',
      maxAge: 60 * 60 * 24 * 7,
      sameSite: 'strict'
    });
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleLogin();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Box className="Main">
      <Box className="Home-content">
        <img src={require('../images/logo.png')} className="App-logo" alt="logo" />
        <TextField
          color="secondary"
          className="Home-input"
          label="Username"
          variant="filled"
          required
          error={fieldEmpty}
          onChange={(e) => {
            const empty = e.target.value === '';

            setFieldEmpty(empty);

            if (!empty) {
              setShowAlert(false);
            }
          }}
        />
        <Button
          sx={{ marginTop: '20px' }}
          className="Home-button"
          variant="contained"
          onClick={handleLogin}>
          Play
        </Button>
        <Snackbar
          open={showAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Please enter a username
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

export default Login;
